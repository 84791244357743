<template>
  <div>
    <h2 class="text-cmain text-center">MYPLR口座を新規開設します</h2>
    <!-- プラン選択 -->
    <b-card no-body class="mb-5 py-3">
      <b-card-body>
        <b-card-title title-tag="h3" class="text-cmain">
          本人性確認方式
        </b-card-title>
        <b-form-group v-bind:disabled="ekycPlanComfirmed">
          <legend>
            本人性確認方式を選択して[決定]ボタンを押し、表示される入力フォームの入力を行ってください。
          </legend>
          <b-form-radio
            v-model="ekycPlan"
            name="ekyc-plan"
            value="1"
            class="box-large"
            >公的個人認証
            <p>マイナンバーカードのICチップの情報を読み取ります。</p>
          </b-form-radio>
          <b-form-radio
            v-model="ekycPlan"
            name="ekyc-plan"
            value="2"
            class="box-large"
            >eKYC「ホ」方式
            <p>下記の写真付き身分証とセルフィーを提出します。</p>
            <ul>
              <li>運転免許証</li>
              <li>パスポート</li>
              <li>マイナンバーカード表面</li>
              <li>住民基本台帳カード</li>
              <li>在留カード</li>
              <li>特別永住者証明書</li>
              <li>外国人登録証明書</li>
              <li>運転経歴証明書</li>
            </ul>
          </b-form-radio>
        </b-form-group>
        <b-form-row>
          <b-col offset="3" cols="6" offset-sm="4" sm="4">
            <b-button
              v-on:click="comfirmEkycPlan"
              v-bind:disabled="ekycPlanComfirmed"
              variant="csub"
              class="w-100"
            >
              決定
            </b-button>
          </b-col>
        </b-form-row>
      </b-card-body>
    </b-card>
    <div ref="inputForm">
      <b-form v-if="ekycPlanComfirmed" v-on:submit.prevent="toConfirm()">
        <!-- 個人情報の入力 -->
        <b-card no-body class="mb-5 py-3">
          <b-card-body>
            <b-card-title title-tag="h3" class="text-cmain">
              個人情報の入力
            </b-card-title>
            <b-card-text v-if="ekycPlan == 1">
              ＊姓名はよみがなを入力ください（漢字氏名はマイナンバーカードから読み取ります）
            </b-card-text>
            <b-form-row v-if="fieldRequired">
              <b-col cols="6">
                <base-input
                  v-model="personalInfo.surname"
                  label="姓"
                  id="surname"
                  v-bind:required="fieldRequired"
                >
                </base-input>
              </b-col>
              <b-col cols="6">
                <base-input
                  v-model="personalInfo.firstname"
                  label="名"
                  id="firstname"
                  v-bind:required="fieldRequired"
                >
                </base-input>
              </b-col>
            </b-form-row>
            <b-form-row>
              <b-col cols="6">
                <base-input
                  v-model="personalInfo.surnameKana"
                  label="姓よみがな"
                  id="surname-kana"
                  v-bind:required="true"
                  pattern="[\u3041-\u3096]*"
                >
                </base-input>
              </b-col>
              <b-col cols="6">
                <base-input
                  v-model="personalInfo.firstnameKana"
                  label="名よみがな"
                  id="firstname-kana"
                  v-bind:required="true"
                  pattern="[\u3041-\u3096]*"
                >
                </base-input>
              </b-col>
            </b-form-row>
            <base-input
              v-if="fieldRequired"
              v-model="personalInfo.dateOfBirth"
              label="生年月日（西暦）"
              id="date-of-birth"
              type="date"
              max="9999-12-31"
              v-bind:required="fieldRequired"
            >
            </base-input>
            <b-form-group v-if="fieldRequired">
              <legend>
                <b-badge variant="caccent">必須</b-badge>
                性別
              </legend>
              <b-form-radio-group
                id="gender"
                v-model="personalInfo.gender"
                v-bind:options="constants.gender"
                name="gender"
                class="box-large"
                v-bind:required="fieldRequired"
              ></b-form-radio-group>
            </b-form-group>
            <base-input
              v-if="fieldRequired"
              v-model="personalInfo.address1"
              description="現在お住まいの住所の都道府県を入力してください。"
              label="住所1（現住所：都道府県）"
              placeholder="例）大阪府"
              id="address1"
              v-bind:required="fieldRequired"
            >
            </base-input>
            <base-input
              v-if="fieldRequired"
              v-model="personalInfo.address2"
              description="現在お住まいの住所の市区町村を入力してください。"
              label="住所2（現住所：市町村区）"
              placeholder="例）大阪市"
              id="address2"
              v-bind:required="fieldRequired"
            >
            </base-input>
            <base-input
              v-if="fieldRequired"
              v-model="personalInfo.address3"
              description="現在お住まいの住所の市区町村以降を入力してください"
              label="住所3（現住所：市町村区以下）"
              placeholder="例）北区梅田1-1-1"
              id="address3"
              v-bind:required="fieldRequired"
            >
            </base-input>
            <base-input
              v-model="personalInfo.phoneNumber"
              description="xxx-xxxx-xxxxの形式で入力してください。"
              label="連絡先電話番号"
              placeholder="例）080-1234-5678 ハイフンあり"
              id="phone-number"
              pattern="[0-9]{3}-[0-9]{4}-[0-9]{4}"
              v-bind:required="true"
            >
            </base-input>
            <base-input
              v-model="personalInfo.email"
              label="メールアドレス"
              id="email"
              type="email"
              v-bind:required="true"
            >
            </base-input>
            <base-select
              v-model="personalInfo.receptionType"
              label="コイン受取方法"
              id="reception"
              value-field="code"
              text-field="name"
              v-bind:options="receptionTypeList"
              v-bind:required="true"
            >
            </base-select>
          </b-card-body>
        </b-card>
        <!-- 本人性確認書類が異なる場合 -->
        <b-card no-body class="mb-5 py-3" v-if="ekycPlan == 2">
          <b-card-body>
            <b-card-title title-tag="h3" class="text-cmain">
              本人性確認書類記載の住所
            </b-card-title>
            <b-card-text>
              本人性確認書類記載の住所が現住所と異なる場合はチェックを入れて入力ください。
              <br />（本人性確認書類に住所の記載がない場合は入力不要です。）
            </b-card-text>
            <b-form-checkbox
              v-model="extraAddressRequired"
              class="box-large mb-3"
              name="extra-address"
            >
              <b>本人性確認書類の住所が現住所と異なる</b>
            </b-form-checkbox>
            <base-input
              v-model="personalInfo.extraAddress1"
              description="本人性確認書類（免許証など）記載の住所の都道府県を入力してください。"
              label="住所1（本人性確認書類記載の住所：都道府県）"
              placeholder="例）大阪府"
              id="extra-address1"
              v-bind:required="extraAddressRequired"
              v-bind:disabled="!extraAddressRequired"
            >
            </base-input>
            <base-input
              v-model="personalInfo.extraAddress2"
              description="本人性確認書類（免許証など）記載の住所の市区町村を入力してください。"
              label="住所2（本人性確認書類記載の住所：市町村区）"
              placeholder="例）大阪市"
              id="extra-address2"
              v-bind:required="extraAddressRequired"
              v-bind:disabled="!extraAddressRequired"
            >
            </base-input>
            <base-input
              v-model="personalInfo.extraAddress3"
              description="本人性確認書類（免許証など）記載の住所の市区町村以降を入力してください"
              label="住所3（本人性確認書類記載の住所：市町村区以下）"
              placeholder="例）北区梅田1-1-1"
              id="extra-address3"
              v-bind:required="extraAddressRequired"
              v-bind:disabled="!extraAddressRequired"
            >
            </base-input>
          </b-card-body>
        </b-card>
        <b-form-row>
          <b-col offset="3" cols="6" offset-sm="4" sm="4">
            <b-button type="submit" variant="caccent" class="w-100">
              入力内容の確認
            </b-button>
          </b-col>
        </b-form-row>
      </b-form>
    </div>
  </div>
</template>

<script>
import BaseInput from '@/components/common/BaseInput'
import BaseSelect from '@/components/common/BaseSelect'
import textGettable from '@/mixins/textGettable'
export default {
  components: {
    BaseInput,
    BaseSelect,
  },
  mixins: [textGettable],
  props: ['storedData', 'receptionTypeList'],
  data() {
    return {
      personalInfo: {
        surname: null,
        firstname: null,
        surnameKana: null,
        firstnameKana: null,
        dateOfBirth: null,
        gender: null,
        genderText: null,
        address1: null,
        address2: null,
        address3: null,
        extraAddress1: null,
        extraAddress2: null,
        extraAddress3: null,
        phoneNumber: null,
        email: null,
        receptionType: null,
        receptionTypeName: null,
      },
      ekycPlan: null,
      ekycPlanComfirmed: false,
      fieldRequired: true,
      extraAddressRequired: false,
    }
  },
  watch: {
    extraAddressRequired(val) {
      // 現住所と異なるchkを外したら入力されているものはクリアする
      if (!val) {
        this.personalInfo.extraAddress1 = null
        this.personalInfo.extraAddress2 = null
        this.personalInfo.extraAddress3 = null
      }
    },
  },
  created() {
    // 保持しているデータをセット
    Object.assign(this.personalInfo, this.storedData.personalInfo)
    this.extraAddressRequired = this.storedData.extraAddressRequired
    this.ekycPlan = this.storedData.ekycPlan
    this.ekycPlanComfirmed = this.storedData.ekycPlanComfirmed
    this.fieldRequired = this.storedData.fieldRequired
  },
  methods: {
    comfirmEkycPlan() {
      // 身元確認プラン確定
      if (!this.ekycPlan) {
        alert('本人性確認方式を選択してください。')
        return
      }
      this.ekycPlanComfirmed = true
      if (this.ekycPlan == 1) {
        this.fieldRequired = false
      }
      // 入力formへscroll
      const element = this.$refs.inputForm
      const position = element.getBoundingClientRect().top
      setTimeout(function () {
        scrollTo(0, position)
      }, 1)
    },
    toConfirm() {
      // データを親に渡して保持する
      this.personalInfo.receptionTypeName = this.receptionTypeList.find(
        (v) => v.code == this.personalInfo.receptionType
      ).name
      this.personalInfo.genderText = this.getText(
        'gender',
        this.personalInfo.gender
      )

      this.$emit(
        'to-confirm',
        this.personalInfo,
        this.ekycPlan,
        this.ekycPlanComfirmed,
        this.fieldRequired,
        this.extraAddressRequired
      )
      // 確認ページへ
      this.$router.push('/account-opening-ekyc/application-form/confirm')
    },
  },
}
</script>
